import React, { useEffect, useState} from 'react';
import './Home.css';
import {Spin} from 'antd';
import { useStoreService } from '../../services';
import moment from "moment";

function Home() {
    const storeService = useStoreService();

    const [loading, setLoading] = useState(false);

    const [storeDetails, setStoreDetails] = useState(false);

    useEffect( () => {
        (async ()=> {
            setLoading(true);
            const data = await getStoreDetails();
            setLoading(false)
            return null
        })();

    }, []);

    const getStoreDetails = async () => {
        try {
            const store_details = storeService.getCurrentStoreDetails();
            const response = await storeService.getStoreDetails(store_details?.store_url);
            console.log(response);

            if(response && response?.data){
                setStoreDetails(response?.data);
            }
            return null
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div fallback={<p>Something went wrong</p>}>
            <Spin spinning={loading}>
                <div className="content_head_ui">
                    <h2 className="heading">Welcome {storeDetails?.store_url}</h2>
                </div>

                <div className='card_detail_wrapper'>
                    <div className='home_content_wrap'>
                        <ul>
                            <li>
                                <label>Store Name: </label>
                                <p>{storeDetails.store_url}</p>
                            </li>
                            <li>
                                <label>Store Type: </label>
                                <p>{storeDetails.type}</p>
                            </li>
                            <li>
                                <label>Joined Date: </label>
                                <p>{moment(storeDetails.created_at * 1000).format('YYYY-MM-DD HH:mm:ss')}</p>
                            </li>
                        </ul>
                    </div>
                    <div className='store_sync_wrap'>
                        <label>Store Sync Key:</label>
                        <p>{storeDetails.store_sync_id}</p>
                    </div>
                </div>
            </Spin>

        </div>
    );
}

export default Home;
