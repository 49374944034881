import useFetchWrapper from '../helpers/FetchWrapper';

function useUserService() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

    const fetchWrapper = useFetchWrapper();

    async function login(store_url) {
        try {
            const overallRoute = `${baseUrl}/login`;
            const user = await fetchWrapper.post(overallRoute, {store_url: store_url});
            //store user details and jwt token in local storage to keep user logged in between page refreshes
            console.log(user,"TOKEN FOR USER")

            sessionStorage.setItem('user', JSON.stringify(user.data?.store_sync_id))

            const user_data = {
                "store_url": user?.data?.store_url,
                "type": user?.data?.type,
                "access_token": user?.data?.access_token,
                "store_sync_id": user?.data?.store_sync_id
            }
            localStorage.setItem(JSON.stringify(user.data?.store_sync_id), JSON.stringify(user_data));
            return user;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    return {
        login
    };
}

export { useUserService };
