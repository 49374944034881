//Purpose: Creator for actions related to stores. Imported StoreService to make async HTTP requests with trigger dispatch on the result

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BASE_API_URL } from '../constants/routes';
import useFetchWrapper from '../helpers/FetchWrapper';
import { StoreActions } from '../redux/slices/store.slice';
import { Notification } from '../utilities/notification';
import axios from 'axios';
import store from "../redux/store";

function
useStoreService() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/stores`;
    const fetchWrapper = useFetchWrapper();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return {
        bulkSyncProducts,
        syncSelectedProduct,
        getAllMappedStores,
        addStoreMap,
        deleteStoreMapping,
        getProducts,
        getStoreDetails,
        getCurrentStoreDetails,
        addStoreType,
        destinationOrderType
    };

    async function deleteStoreMapping(queries){
        try{
            const overAllRoute = `${baseUrl}/delete_store_mapping`;
            const res = await fetchWrapper.post(overAllRoute, queries);
            return res
        }
        catch (e) {
            console.log(e);
        }
    }

    async function destinationOrderType(queries){
        try{
            const overAllRoute = `${baseUrl}/add_destination_order_type`;
            const stores = await fetchWrapper.post(overAllRoute, queries);
            return stores
        }
        catch (err){
            console.log(err);
        }
    }

    async function addStoreType(queries){
        try{
            const overAllRoute = `${baseUrl}/add_store_type`;
            const stores = await fetchWrapper.post(overAllRoute, queries);
            return stores
        }
        catch (err){
            console.log(err);
        }
    }

    function getCurrentStoreDetails(){
        try{
            const store_sync_id = sessionStorage.getItem('user');
            const store_details = JSON.parse(localStorage.getItem(store_sync_id));

            return store_details;
        }
        catch (err){
            console.log(err);
        }
    }

    async function getStoreDetails(store_url){
        try{
            const overAllRoute = `${baseUrl}/store_details?store_url=${store_url}`;
            const stores = await fetchWrapper.get(overAllRoute);
            return stores
        }
        catch (err){
            console.log(err);
        }
    }


    async function getAllMappedStores(queries){
        try {
            const response = await fetchWrapper.get(
                `${BASE_API_URL}/stores/get_all_connected_store?store_sync_id=${queries?.store_sync_id}&type=${queries?.type}`
            );
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function getSourceStores(queries) {
        try {
            const data = {
                page: queries?.page || 1,
                pageSize: 10,
                searchText: queries?.searchText || '',
                sort: "asc",
                destinationstore_sync_id: queries?.store_sync_id
            };
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/stores/get_source_stores`, data
            );
            return response;
        } catch (err) {
            console.log(err);
        }
    }

    async function addStoreMap(request) {
        try {
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/stores/map_store`, request
            );
            return response;
        } catch (err) {
            console.log(err);
            return err?.response?.data;
        }
    }

    async function bulkSyncProducts(queries){
        try {
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/bulksync/bulkSyncProducts`, queries
            );
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async function syncSelectedProduct(queries){
        try {
            const data = {
                source_sync_id: queries.source_sync_id,
                destination_sync_id: queries.destination_sync_id,
                products_list: queries.products_list
            };
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/bulksync/syncSelectedProducts`, data
            );
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async function getProducts(queries) {
        try {
            const response = await fetchWrapper.post(
                `${BASE_API_URL}/bulksync/getSourceProductsList`, queries
            );
            return response;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export { useStoreService };
