//Purpose: Create a service module that uses fetch API to send HTTP requests to backend.

import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BASE_API_URL } from '../constants/routes';
import {useStoreService} from "../services";

function useFetchWrapper() {
    const { token } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE')
    };

    function request(method) {
        return (url, body) => {
            const requestOptions = {
                url: url,
                method: method,
                headers: authHeader(url)
            };
            if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.data = body;
            }
            return axios.request(requestOptions).catch((err) => {
                console.log("error", err);
                console.log("before function call")
                handle_response(err)
                throw err;
            });
        };
    }

    //helper functions
    function authHeader(url) {
        const store_sync_id = sessionStorage.getItem('user');
        const store_details = JSON.parse(localStorage.getItem(store_sync_id));

        const token = store_details?.access_token;
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(BASE_API_URL);
        if (isLoggedIn && isApiUrl) {
            const validToken = store_details ? token: null;
            return {
                'x-access-Token': `${token}`,
                Accept: 'application/json',
                Authorization: 'Bearer ' + validToken
            };
        } else {
            return {};
        }
    }

    function handle_response(response){
        console.log(response?.response?.status)
        const status_code = response?.response?.status

        if(status_code === 401){
            navigate('/session_expired');
        }
    }
}
export default useFetchWrapper;
