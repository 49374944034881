
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Button, Card, Col, Row} from 'antd';
import React, {useEffect,useState} from "react";
import {useStoreService} from "../services";
import {useWebhookSerive} from "../services/webhooks.service"
import {useUserService} from "../services";
import {Notification} from "../utilities/notification";

const StoreDetails = ({store})=>{
    const [searchParams] = useSearchParams();
    const[shop,setShop] =useState()
    const storeService=useStoreService();
    const webhookService = useWebhookSerive();
    const userService = useUserService();
    const navigate = useNavigate();
    const getQueryParams = () => {
        const hmac = searchParams.get('hmac');
        const host = searchParams.get('host');
        const shop = searchParams.get('shop');
        const timestamp = searchParams.get('timestamp');

        return { hmac, host, shop, timestamp };
    };

    // useEffect(() => {
    //     sendQueryParams();
    // }, []); //eslint-disable-line
    //
    // // Send Request to backend
    // const sendQueryParams = async () => {
    //     const { hmac, host, shop, timestamp } = getQueryParams();
    //     //Checking query params to process the request
    //     setShop(shop)
    //
    // };

    const addStoreType = async (store_type)=>{
        try{
            const store_details = storeService.getCurrentStoreDetails();
            let store = {
                store_sync_id: store_details?.store_sync_id || "",
                type: store_type
            };

            const response = await storeService.addStoreType(store);
            console.log(response)

            if(store_type == "destination"){
                const destination_order_type_response = await storeService.destinationOrderType({
                    store_sync_id: store_details.store_sync_id || "",
                    type: "manual"
                })
            }

            const webhooks_response = await webhookService.addWebhooks(store_details?.store_url)

            if (webhooks_response?.status === 200){
                const login_details_response = await userService.login(store_details?.store_url);
                const login_details = login_details_response?.data;
                console.log(login_details);

                if (!login_details) {
                    console.log("error in login");
                    Notification('error', "Error encountered while login");
                    return
                }
            }
            else{
                console.log("error in adding store type");
                Notification('error', "Error encountered while adding store type");
                return
            }

            navigate('/');
        }
        catch (err){
            console.log(err);
        }
    }

    return(


        <div className="content_wrapper">
            <h2 className="productTitle">
                <svg width="160" height="40" viewBox="0 0 160 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_304_400)">
                        <path d="M47.4089 9.37691C49.156 9.37691 50.3824 8.20694 50.3824 6.32478C50.3824 4.71393 49.156 3.54395 47.6105 3.54395C46.0145 3.54395 44.7377 4.71393 44.7377 6.42652C44.7545 8.03737 45.8297 9.37691 47.4089 9.37691ZM46.3841 33.404C46.3841 35.5914 46.3337 36.4053 44.5865 36.8801C44.0153 37.0497 43.6121 37.304 43.6121 37.9992C43.6121 39.0675 44.5361 39.1692 45.3593 39.1692H51.1552C52.0792 39.1692 52.852 39.0675 52.852 37.9992C52.852 37.2531 52.3312 37.0327 51.5248 36.7105C50.2984 36.2358 50.248 35.6423 50.248 32.5901V20.1781C50.248 13.4804 49.4752 13.4804 48.7024 13.4804C48.0809 13.4804 46.9049 14.1247 46.3505 14.396C43.3266 15.8373 43.125 16.2103 43.125 16.8547C43.125 17.499 43.7465 17.7025 44.5025 17.872C46.4009 18.2451 46.4009 19.8051 46.4009 22.0433V33.404H46.3841Z" fill="#006878"/>
                        <path d="M125.543 29.2836C125.543 33.7262 122.872 36.9988 117.798 36.9988C114.774 36.9988 112.775 34.8114 112.775 32.0814C112.775 26.5198 118.924 25.5193 123.023 25.5193H125.526V29.2836H125.543ZM131.087 36.8801C129.81 36.507 129.39 35.8627 129.39 32.5901V24.5359V23.9594C129.39 17.2107 125.14 13.4634 118.168 13.4634C116.27 13.4634 114.069 13.8873 112.12 14.8538C111.347 15.2777 110.07 16.1425 110.07 17.1599C110.07 18.0755 110.742 18.4994 111.498 18.4994C113.447 18.4994 113.649 16.0407 118.369 16.0407C122.015 16.0407 125.241 18.4994 125.543 22.5689V23.0776H122.267C112.12 23.0776 108.693 28.351 108.693 32.3188C108.693 37.4566 112.943 39.9661 117.412 39.9661C121.108 39.9661 124.031 38.1518 125.98 34.9301V36.2697C125.98 38.2027 125.98 39.1522 127.878 39.1522H130.129C130.952 39.1522 132.179 39.2031 132.179 37.9822C132.162 37.2531 131.641 37.0496 131.087 36.8801Z" fill="#006878"/>
                        <path d="M94.2298 37.3718C88.5348 37.3718 85.7292 32.4545 85.7292 26.9945C85.7292 21.4837 88.4004 16.1255 94.3474 16.1255C100.042 16.1255 103.066 20.992 103.066 26.5028C103.049 33.5058 98.581 37.3718 94.2298 37.3718ZM94.4314 13.4973C87.51 13.4973 81.6133 19.5507 81.6133 26.7741C81.6133 34.0484 87.2076 40 94.1626 40C101.436 40 107.131 34.2179 107.131 26.5706C107.148 19.2794 101.352 13.4973 94.4314 13.4973Z" fill="#006878"/>
                        <path d="M57.6575 32.6071C57.6575 35.8797 57.3551 36.3036 56.3303 36.7275C55.356 37.1005 54.9528 37.321 54.9528 37.9653C54.9528 39.2031 56.028 39.2031 56.9015 39.2031H62.899C63.6214 39.2031 64.2262 38.7792 64.2262 37.9653C64.2262 37.2701 63.7054 37.0497 62.6806 36.6766C61.5047 36.2527 61.5047 35.4388 61.5047 32.6071V23.7729C61.5047 21.6872 61.6054 20.1951 63.151 18.686C64.579 17.3464 66.6286 16.4308 68.7957 16.4308C72.6428 16.4308 74.7428 18.7368 74.7428 22.8063V32.6071C74.7428 35.3371 74.5916 36.1849 73.718 36.6258L73.1972 36.8971C72.7268 37.1684 72.374 37.3718 72.374 37.9653C72.374 39.2031 73.3988 39.2031 74.2724 39.2031H79.8499C80.5219 39.2031 81.1771 38.7792 81.1771 37.9653C81.1771 37.2701 80.7235 37.0497 79.4803 36.5749C78.7579 36.3036 78.5563 35.5575 78.5563 32.6071V23.8237C78.5563 21.0429 78.3043 18.5333 76.4564 16.3799C74.7596 14.4469 72.2564 13.4973 69.8373 13.4973C65.8894 13.4973 63.2686 14.9895 60.8663 18.1603C60.5135 15.1082 59.9927 13.4973 58.7159 13.4973C57.8927 13.4973 56.7167 14.6164 56.0952 15.1082C55.5744 15.5321 54.8184 16.0747 54.8184 16.8716C54.8184 17.4651 55.5408 17.6686 56.5655 18.0416C57.4895 18.3638 57.6407 19.2794 57.6407 21.789V32.6071H57.6575Z" fill="#006878"/>
                        <path d="M40.0672 36.6257L39.0256 36.3205C38.3872 36.134 37.9505 35.5405 37.9337 34.8622L37.2113 4.37473C37.1945 3.66257 37.6481 3.01823 38.32 2.83171L39.4288 2.52649C40.0504 2.37389 40.8064 2.15346 40.8064 1.35651C40.8064 0.118694 40.252 0.0169563 39.3784 0.0169563H32.7594C31.8858 0.0169563 31.533 0.559559 30.861 2.27215C27.7867 10.3095 24.1915 18.6011 20.3444 26.8928C16.7661 18.8385 13.6917 10.8181 10.567 2.83171C9.52541 0.169563 9.32382 0 7.99664 0H1.64637C0.873583 0 0.251995 0.32217 0.251995 1.16999C0.251995 1.91607 0.789585 2.08563 1.42797 2.22128L2.36875 2.4078C3.09114 2.54345 3.61193 3.20475 3.59513 3.98474L2.83914 34.9979C2.82234 35.6253 2.45275 36.1848 1.88156 36.4053L1.20958 36.6766C0.571189 36.9309 0 37.1513 0 38.0161C0 39.1861 0.923982 39.1861 1.84796 39.1861H7.99664C8.66863 39.1861 9.37422 38.9148 9.37422 38.1178C9.37422 37.287 8.78623 36.9818 7.77825 36.6426L7.42545 36.524L6.82067 36.3205C6.24948 36.134 5.86308 35.5744 5.87988 34.947L6.61907 4.61212C10.1134 14.2942 13.9605 23.4845 18.4292 33.1157C18.95 34.2688 20.3444 34.4892 20.9996 33.1157C24.8803 24.7563 29.3322 14.5655 32.8266 5.45994L33.5489 35.1166C33.5657 35.71 33.2129 36.2527 32.6922 36.4731L32.037 36.7274C31.3146 37.0157 30.9786 37.2531 30.9786 37.9483C30.9786 39.0674 31.953 39.1861 32.7762 39.1861H39.6976C40.5712 39.1861 41.5456 39.1352 41.5456 37.9483C41.512 37.1344 41.008 36.9139 40.0672 36.6257Z" fill="#006878"/>
                        <path d="M136.464 32.6071C136.464 35.8797 136.161 36.3036 135.137 36.7275C134.162 37.1005 133.759 37.321 133.759 37.9653C133.759 39.2031 134.834 39.2031 135.708 39.2031H141.705C142.428 39.2031 143.032 38.7792 143.032 37.9653C143.032 37.2701 142.512 37.0497 141.487 36.6766C140.311 36.2527 140.311 35.4388 140.311 32.6071V23.7729C140.311 21.6872 140.412 20.1951 141.957 18.686C143.385 17.3464 145.435 16.4308 147.602 16.4308C151.449 16.4308 153.549 18.7368 153.549 22.8063V32.6071C153.549 35.3371 153.398 36.1849 152.524 36.6258L152.003 36.8971C151.533 37.1684 151.18 37.3718 151.18 37.9653C151.18 39.2031 152.205 39.2031 153.079 39.2031H158.673C159.345 39.2031 160 38.7792 160 37.9653C160 37.2701 159.53 37.0497 158.303 36.5749C157.581 36.3036 157.379 35.5575 157.379 32.6071V23.8237C157.379 21.0429 157.127 18.5333 155.279 16.3799C153.583 14.4469 151.079 13.4973 148.66 13.4973C144.712 13.4973 142.092 14.9895 139.689 18.1603C139.336 15.1082 138.816 13.4973 137.539 13.4973C136.716 13.4973 135.54 14.6164 134.918 15.1082C134.397 15.5321 133.641 16.0747 133.641 16.8716C133.641 17.4651 134.364 17.6686 135.389 18.0416C136.312 18.3638 136.464 19.2794 136.464 21.789V32.6071Z" fill="#006878"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_304_400">
                            <rect width="160" height="40" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

            </h2>
            <div className="shop_page_container">
                <h2 className="subheading">Select Source And Destination
                </h2>

            <div className='storepage_wrapper'>
                <div className='select-type-card'>
                    <Card title="Destination" className='field_wrap' bordered={false}>

                        {/*{shop}*/}
                        {/*lorem ispum dummy text lorem ispum dummy textlorem ispum dummy text*/}

                    </Card>
                    <Button onClick={()=>addStoreType("destination")} type="primary" >Add As Destination </Button>
                </div>
                <div className='spacer'>
                    <span>OR</span>
                </div>
                <div className='select-type-card'>
                    <Card title="Source" className='field_wrap' bordered={false}>


                        {/*{shop}*/}
                        {/*lorem ispum dummy text lorem ispum dummy textlorem ispum dummy t*/}

                    </Card>

                    <Button onClick={()=>addStoreType("source")} type="primary" >Add As Source </Button>
                </div>
                {/*<pre> {shop}</pre>*/}

            </div>
            </div>
        </div>
    )

} ;



export default StoreDetails;