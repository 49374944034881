import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import '../src/assets/css/custom.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReduxStore from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={ReduxStore}>
        <App />
    </Provider>
);

reportWebVitals();
