import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {useOrderService} from "./order.service";
import { BASE_API_URL } from '../constants/routes';
import useFetchWrapper from '../helpers/FetchWrapper';
// import crypto from 'crypto';
import { useStoreService } from "./store.service";
import {useUserService} from "./user.service";
import store from "../redux/store";
import {notification} from "antd";
import { Notification } from '../utilities/notification';

function useOAuthService() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/webhook`;

    const navigate = useNavigate();
    const fetchWrapper = useFetchWrapper();
    const user_service = useUserService();

    console.log(baseUrl, "base_url")

    //OAuth Initiater
    async function beginOAuth(data) {
        try {
            const overallRoute = `${baseUrl}/auth/begin?hmac_param=${data.hmac}&host=${data.host}&shop=${data.shop}&timestamp=${data.timestamp}&locale=${data.locale ? data.locale : ""}&session=${data.session ? data.session : ""}`;
            const response = await fetchWrapper.get(overallRoute);
            console.log(response)
            console.log(response?.data)

            if(response.status === 200) {

                if( response?.data.includes("myshopify.com") ){
                    window.location.href = response?.data;
                }
                else{
                    await login(data.shop)
                }
            }
            else{
                Notification("error", "Please try reinstalling the app")
                // navigate('/');
            }
        } catch (err) {
            console.log(err);
        }
    }

    //OAuth complete
    async function endOAuth(data) {
        try {
            // After users's confirmation, sending session to backend to create token
            console.log("end api call");
            const overallRoute = `${baseUrl}/auth/end?code=${data.code}&hmac_param=${data.hmac}&host=${data.host}&shop=${data.shop}&timestamp=${data.timestamp}`;
            const response = await fetchWrapper.get(overallRoute);
            console.log(response);

            if(response.status === 200) {
                await login(data.shop)
            }
            else{
                console.log("error in auth end");
                Notification('error', "Error in installing the app, Kindly try to reinstall");
            }

        } catch (err) {
            console.log(err);
        }
    }

    async function login(url){
        const login_details_response = await user_service.login(url);
        const login_details = login_details_response?.data;
        console.log(login_details);

        if (!login_details) {
            console.log("error in login");
            Notification('error', "Error encountered while login");
        }
        else
        {
            navigate('/home');
        }
    }

    return {
        beginOAuth,
        endOAuth
    };
}

export { useOAuthService };
