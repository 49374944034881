import useFetchWrapper from "../helpers/FetchWrapper";

function useWebhookSerive(){

    const baseUrl = `${process.env.REACT_APP_API_URL}/webhook`;
    const fetchWrapper = useFetchWrapper();

    async function addWebhooks(store_url){
        try{
            const overAllRoute = `${baseUrl}/add_webhooks?store_url=${store_url}`;
            const stores = await fetchWrapper.get(overAllRoute);
            return stores
        }
        catch (err){
            console.log(err);
        }
    }

    return {
        addWebhooks
    };
}


export {useWebhookSerive}