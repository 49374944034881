import {
    UploadOutlined,
    ShopOutlined,
    HomeOutlined,
    UnorderedListOutlined,
    ShoppingCartOutlined,
    SettingOutlined,
    ContainerOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import React, {useEffect, useState} from 'react';
import { useStoreService } from '../../services';
import { Link } from 'react-router-dom';

import { useUserService } from '../../services';

import './SiderMenu.css';
const { Sider } = Layout;
const SiderMenu = () => {

    const storeService = useStoreService();
const[store,setStore] = useState()
    useEffect(()=>{
        const store_details = storeService.getCurrentStoreDetails();
        setStore(store_details)
        console.log("SIDER",store_details)
    },[])
    // const { logout } = useUserService();

    // const onClickLogoutHandler = async () => {
    //     await logout();
    // };

    return (
        <Sider
            width={220}
            className="site-layout-background side-navigation"
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 60,
                bottom: 0,
            }}
        >
            {/*<Button*/}
            {/*    onClick={onClickLogoutHandler}*/}
            {/*    className="logOutButton text_btn"*/}
            {/*    icon={<UploadOutlined rotate="90" />}*/}
            {/*    style={{*/}
            {/*        overflow: 'auto',*/}
            {/*        bottom: '1%',*/}
            {/*        position: 'fixed',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Logout*/}
            {/*</Button>*/}

            <Menu
                mode="inline"
                style={{
                    height: '100%',
                }}
            >

                {store && store.type === "source" ? ( <>

                    <Menu.Item key="1" icon={<HomeOutlined />}>
                        Home
                        <Link to="home" />
                    </Menu.Item>
                    <Menu.Item key="2" icon={<ShopOutlined />}>
                        Store Mappings
                        <Link to="storeMapping" />
                    </Menu.Item>
                </>):(<>

                    <Menu.Item key="1" icon={<HomeOutlined />}>
                        Home
                        <Link to="home" />
                    </Menu.Item>
                    <Menu.Item key="2" icon={<ShopOutlined />}>
                        Store Mappings
                        <Link to="storeMapping" />
                    </Menu.Item>
                    <Menu.Item key="3" icon={<UnorderedListOutlined />}>
                        Product
                        <Link to="products" />
                    </Menu.Item>
                    <Menu.Item key="4" icon={<ShoppingCartOutlined />}>
                        Orders
                        <Link to="orders" />
                    </Menu.Item>
                </>)}

                {/* <Menu.Item key="4" icon={<ContainerOutlined />}>
                    Order
                    <Link to="order" />
                </Menu.Item>
                <Menu.Item key="5" icon={<SettingOutlined />}>
                    Settings
                    <Link to="settings" />
                </Menu.Item> */}
            </Menu>
        </Sider>
    );
};

export default SiderMenu;
