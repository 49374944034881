import {React, useState, useEffect, useMemo, useCallback, useRef} from 'react';
import './Products.css';
import {Button, Table, Modal, Tag, Row, Col, Pagination, Select as Ant_Select, Menu} from 'antd';
import {
    SyncOutlined
} from '@ant-design/icons';
import Select from "react-select";
import Tooltip from 'antd/es/tooltip';
import syncErrorIcon from '../../assets/icons/svg/syncErrorIcon.svg';
import { useStoreService } from '../..//services';
import { Notification } from '../../utilities/notification';
import { useSearchParams } from 'react-router-dom';
import ProductModal from "./ProductDetails";
import { Drawer } from "antd";
import { useUserService } from '../../services/user.service'
import { JSEncrypt } from "jsencrypt";


import {tab} from "@testing-library/user-event/dist/tab";
import {DateRangePicker} from "rsuite";
import {debounce} from "lodash";
import Dropdown from "antd/es/dropdown/dropdown";
import Checkbox from "antd/es/checkbox/Checkbox";
const {Option}=Ant_Select;
const SyncErrorIcon = () => <img src={syncErrorIcon} alt="syncError Icon" />;

const column = [
    {
        title: ''
    },
    {
        title: 'PRODUCT ID',
        dataIndex: 'product_id'

    },
    {
        title: 'Product Name',
        dataIndex: 'product'

    },
    {
      title: 'STATUS',
        dataIndex: 'status',
        render: (_, { status }) => {
          return (
              <>
                  {
                      status != 'active' ?
                              <Button className='status-label not-synced' type="primary">
                                  {status}
                              </Button>

                              :

                              <Button className='status-label' type="primary"  style={{ background: "green"}}>
                                  {status}
                              </Button>
                  }
              </>
          )
        },
    },
    {
        title: 'VENDOR',
        dataIndex: 'vendor'
    }
];

function ProductDetails() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [tabledata, setTabledata] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [currentPageUrl, setCurrentPageUrl] = useState('');
    const [filter, setFilter] = useState('');
    const [isOpen, setOpen] = useState(false);
    const [productId, setProductId] = useState(null);
    const [storeId, setStoreId] = useState(null);


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,

    };


    const [searchParams] = useSearchParams();
    const hasSelected = selectedRowKeys.length >= 0;
    const [modalOpen, setModalOpen] = useState(false);
    const [currentStoreSelected, setCurrentStoreSelected] = useState(null);
    const [currentStores, setCurrentStores] = useState([]);
    const [otherStores, setOtherStores] = useState([]);
    const [otherStoreSelected, setOtherStoreSelected] = useState(null);
    const [loadingStores, setLoadingStores] = useState(false);
    const [syncStatus, setSyncStatus] = useState(null);
    const [previousPageLink, setPreviousPageLink] = useState("")
    const [nextPageLink, setNextPageLink] = useState("")
    const [brandFilter, setBrandFilter] = useState("");
    const [selectStoreUrl, setSelectStoreUrl] = useState("");

    const storeService = useStoreService();
    useEffect(() => {
        (async () => {
            setLoadingStores(true);
            const select_store_url = await checkRequestFromMinOs();
            await getMappedStoresList(select_store_url);
            setLoadingStores(false);
        })();
    }, []);

    const user_service = useUserService();

    function decodeBase64(encodedString) {
        // Decode the Base64 string to a standard string

        const privateKey = `
    -----BEGIN RSA PRIVATE KEY-----
    MIICXQIBAAKBgQDlOJu6TyygqxfWT7eLtGDwajtNFOb9I5XRb6khyfD1Yt3YiCgQ
    WMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76xFxdU6jE0NQ+Z+zEdhUTooNR
    aY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4gwQco1KRMDSmXSMkDwIDAQAB
    AoGAfY9LpnuWK5Bs50UVep5c93SJdUi82u7yMx4iHFMc/Z2hfenfYEzu+57fI4fv
    xTQ//5DbzRR/XKb8ulNv6+CHyPF31xk7YOBfkGI8qjLoq06V+FyBfDSwL8KbLyeH
    m7KUZnLNQbk8yGLzB3iYKkRHlmUanQGaNMIJziWOkN+N9dECQQD0ONYRNZeuM8zd
    8XJTSdcIX4a3gy3GGCJxOzv16XHxD03GW6UNLmfPwenKu+cdrQeaqEixrCejXdAF
    z/7+BSMpAkEA8EaSOeP5Xr3ZrbiKzi6TGMwHMvC7HdJxaBJbVRfApFrE0/mPwmP5
    rN7QwjrMY+0+AbXcm8mRQyQ1+IGEembsdwJBAN6az8Rv7QnD/YBvi52POIlRSSIM
    V7SwWvSK4WSMnGb1ZBbhgdg57DXaspcwHsFV7hByQ5BvMtIduHcT14ECfcECQATe
    aTgjFnqE/lQ22Rk0eGaYO80cc643BXVGafNfd9fcvwBMnk0iGX0XRsOozVt5Azil
    psLBYuApa66NcVHJpCECQQDTjI2AQhFc1yRnCU/YgDnSpJVm1nASoRUnU8Jfm3Oz
    uku7JUXcVpt08DFSceCEX9unCuMcT72rAQlLpdZir876
    -----END RSA PRIVATE KEY-----`;

        const decrypt = new JSEncrypt();
        decrypt.setPrivateKey(privateKey);
        const decodedString = decrypt.decrypt(encodedString);

        return decodedString
    }


    const checkRequestFromMinOs= async ()=>{
        // Get the current URL
        const currentUrl = window.location.href;

        // Create a URL object
        const url = new URL(currentUrl);

        // Get the search parameters from the URL
        const searchParams = new URLSearchParams(url.search);

        // Retrieve specific parameter values
        let param = searchParams.get('minos');

        console.log('param:', param);

        if(!param) return false;

        param = decodeBase64(param);

        // Log the parameter values
        console.log('param products :', param);
        // console.log(typeof(param), "param type")

        param = JSON.parse(param);

        // console.log(param["store_url"], "store url")

        setSelectStoreUrl(param["select_store_url"]);


        const login_details_response = await user_service.login(param["store_url"]);
        const login_details = login_details_response?.data;
        console.log(login_details);

        if (!login_details) {
            console.log("error in login");
            Notification('error', "Error encountered while login");
            return false;
        }

        return param["select_store_url"]
    }

    const isFirstRender = useRef(true);

    // Your useEffect hook
    useEffect(() => {
        // Skip running the effect on initial render
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        // Run your logic here
        handleOtherDropdownChange(otherStoreSelected);
    }, [filter, brandFilter, selectStoreUrl]);


    const getMappedStoresList = async (select_store_url) => {
        const current_store_details = storeService.getCurrentStoreDetails();
        setCurrentStoreSelected(current_store_details);

        try {
            const response = await storeService.getAllMappedStores({
                store_sync_id: current_store_details?.store_sync_id,
                type: current_store_details.type
            });

            let responseStoreData = response.data;
            console.log(responseStoreData);

            let processedData = responseStoreData?.map((item) => {
                return {
                    key: item.user_id,
                    store_url: item.store_url,
                    store_sync_id: item.store_sync_id,
                    status: [item.status],
                    brand_name: (item?.brand_name ? item.brand_name : item.store_url)
                }
            });

            setOtherStores(processedData);

            if(select_store_url){
                const selectStoreDetails = processedData?.filter((item) => {
                    if(item.store_url == select_store_url) return item;
                });
                console.log(selectStoreDetails, "selectStoreDetails");
                setOtherStoreSelected(selectStoreDetails);
                await handleOtherDropdownChange(selectStoreDetails , "source");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSync = async () => {
        try {
            console.log("Syncing selected items:", selectedRowKeys);
            if(!selectedRowKeys || !selectedRowKeys.length){
                Notification('error', 'Select at least one product to sync');
                return;
            }

            setLoading(true);

            const response = await bulkSyncProducts(selectedRowKeys);
            if(response){
                Notification('success', 'Product Sync started successfully, please refresh after some time.');
            }
            else{
                Notification('error', 'error in Product Sync.');
            }

            setLoading(false);
        }
        catch (e){
            Notification('error', 'error in Product Sync.');
            console.log(e);
        }
    };

    const startBulkMaping = async  () => {
        try {
            console.log(tabledata);
            setModalOpen(false);

            const request_body = {
                source_sync_id: otherStoreSelected?.store_sync_id,
                destination_sync_id: currentStoreSelected?.store_sync_id
            }

            const response = await storeService.bulkSyncProducts(request_body);
            if(response){
                Notification('success', 'Sync Full Catalog started successfully, please refresh after some time.');
            }
            else{
                Notification('error', 'error in Bulk mapping.');
            }

            setLoading(false);
        }
        catch (e) {
            Notification('error', 'error in Bulk mapping.');
            console.log(e);
        }

    }

    const bulkSyncProducts = async (selectedProducts) => {
        try {

            const requestbody = {
                products_list: selectedProducts,
                source_sync_id: "",
                destination_sync_id: ""
            }

            if (currentStoreSelected?.type === 'source') {
                requestbody.source_sync_id = currentStoreSelected?.store_sync_id
                requestbody.destination_sync_id = otherStoreSelected?.store_sync_id
            } else {
                requestbody.destination_sync_id = currentStoreSelected?.store_sync_id
                requestbody.source_sync_id = otherStoreSelected?.store_sync_id
            }

            return await storeService.syncSelectedProduct(requestbody);
        }
        catch (e){
            console.log(e);
        }
    }

    const otherDropDownOptions = useMemo(() => {
        return otherStores?.map((store) => ({
            label: store.brand_name,
            value: store.store_sync_id
        }));
    }, [otherStores]);

    const handleOtherDropdownChange = async (otherStoreData, action = "", page = 1) => {
        setLoadingTable(true);
        console.log("CUrrent",currentStoreSelected,otherStoreData)
        let requestbody = {};
        let since_id=0;
        if (currentStoreSelected?.type === 'source' || action === "source") {
            requestbody = {
                source_sync_id: action === "source" ? otherStoreData[0].store_sync_id : currentStoreSelected.store_sync_id ,
                destination_sync_id: action === "source" ? otherStoreData[0].store_sync_id : currentStoreSelected.store_sync_id ,
                since_id: since_id,
                action: action,
                filter: filter,
                status_filter: brandFilter,
                skip_limit: Math.max(0, page - 1),
                product_status_filter:finalOptions.length?finalOptions.join(','):''
            }
        } else {
            requestbody = {
                source_sync_id: otherStoreData?.store_sync_id || otherStoreData[0]?.store_sync_id ,
                destination_sync_id: currentStoreSelected?.store_sync_id,
                since_id: since_id,
                action: action,
                filter: filter,
                status_filter: brandFilter,
                skip_limit: Math.max(0, page - 1),
                product_status_filter:finalOptions.length?finalOptions.join(','):''

            }
        }
        try {
            const response = await storeService.getProducts(requestbody);
            console.log(response.data, "response")
            const products = response.data?.product_list;
            setTotalRecords(response?.data?.total_product_list);
            let processedData = products?.map((item) => {
                return {
                    key: item.product_id,
                    product_id: item.product_id,
                    product: item.title,
                    // store_url: item.store_url,
                    // store_sync_id: item.store_sync_id,
                    status: item.status,
                    // product_type: item.product_type,
                    vendor: item.vendor
                }
            });

            console.log(processedData, "processed data")
            setCurrentPageUrl(requestbody.action)
            setPreviousPageLink(response?.data?.previous_page_link)
            setNextPageLink(response?.data?.next_page_link)
            setTabledata(processedData);
            setCurrentPage(page);
            setLoadingTable(false);
        } catch (error) {
            console.log(error);
            setLoadingTable(false);
            setTabledata([]);
        }
    }
    // const debouncedFetch = debounce(handleOtherDropdownChange(otherStoreSelected), 500);


    const handleFilterChange = async event => {
        setFilter(event.target.value?event.target.value:"");
        console.log('value is:', event.target.value);
    };



    const items = [
        {
            key: '',
            label: "Select Product Status"
        },
        {
            key: 'synced',
            label: "Synced"
        },
        {
            key: 'un_synced',
            label: "Not Synced"
        }
    ];

    const handleBrandChange = (value) => {
        console.log(value)
        setBrandFilter(value);
        setCurrentPage(1);
    };
    // const showDrawer = () => {
    //     setProductId("8268418646255");
    //     setStoreId("356555257157305024");
    // };
    const handleClose = () => {
        setProductId(null);
        setStoreId(null);
        setOpen(false);
    };
    useEffect(() => {
        if (productId && storeId) {
            setOpen(true);
        }
    }, [productId, storeId]);

    const [initialRender,setInitialRender]=useState(true);



    const [selectedOptions, setSelectedOptions] = useState(['ACTIVE']);
    const [isVisible,setIsVisible]=useState(false);
    const [finalOptions,setFinalOptions]=useState(['ACTIVE']);

    useEffect(()=>{
        if(initialRender){
            setInitialRender(false);
        } else {
            handleOtherDropdownChange(otherStoreSelected, currentPageUrl, currentPage);

        }
    },[finalOptions])
    const onApply=()=>{
        setFinalOptions(selectedOptions);
        //call api
        setIsVisible(false);
    }

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        console.log(dropdownRef);
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    //
    // const handleVisibleChange = (flag) => {
    //     setVisible(flag);
    // };

    const handleOptionClick = (option) => {
        const selectedIndex = selectedOptions.indexOf(option);
        const newSelectedOptions = [...selectedOptions];

        if (selectedIndex === -1) {
            newSelectedOptions.push(option);
        } else {
            newSelectedOptions.splice(selectedIndex, 1);
        }

        setSelectedOptions(newSelectedOptions);
    };
    const handleVisibleChange = (flag) => {
        setIsVisible(flag);
    };
    const options=['ACTIVE','DRAFT','ARCHIVED']

    const menu = (
        <Menu className={'status_dropdown'}>
            {options.map(option => (
                <Menu.Item key={option}>
                    <Checkbox
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleOptionClick(option)}
                    >
                        {option}
                    </Checkbox>
                </Menu.Item>
            ))}
           <div className={'apply_btn_wrapper'}>
               <Button className={'ant-btn-default1 apply_btn'} onClick={onApply}>Apply</Button>
           </div>
        </Menu>
    );


    return (
        <>
            {isOpen === false ?
                <>
                    <div className="content_head_ui"><h2
                        className="heading">Product </h2> {currentStoreSelected && otherStoreSelected && (
                        <Tooltip
                            title={syncStatus === 'pending' ? "Bulk mapping is in progress. Please come back after sometime." : ''}
                            placement='bottom'>
                            <></>
                            {/*<div ref={dropdownRef} className={'product_status_btn'}>*/}
                            <Dropdown  className=" ant-btn-default1 product_status_btn" overlay={menu} trigger={['click']} visible={isVisible} onVisibleChange={handleVisibleChange}
                            >
                                <Button onClick={()=>{
                                    setIsVisible(!isVisible);
                                    setSelectedOptions(finalOptions);
                                }}>Status {finalOptions.length?`(${finalOptions.length})`:''}
                                <span className="status_icons">
                                    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L8.75 8.75L16.5 1" stroke="#dc5a4b" stroke-width="1.9375" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                </Button>

                            </Dropdown>
                            {/*</div>*/}
                            {/*<Button*/}
                            {/*    className={`bulkMapperButton primary_btn`}*/}
                            {/*    icon={<SyncOutlined/>}*/}
                            {/*    type="primary"*/}
                            {/*    disabled={false}*/}
                            {/*    onClick={() => setModalOpen(true)}*/}
                            {/*    loading={syncStatus === 'pending'}*/}
                            {/*>*/}
                            {/*    Sync Full Catalog*/}
                            {/*</Button>*/}
                        </Tooltip>
                    )}
                    </div>
                    <div
                        className="storeSelection  store_dropdown_wrap"
                    >
                        <div className='top-container'>

                            <div className='dropdown-wrapper'>
                                <h3>{currentStoreSelected?.type === 'source' ? 'Destination' : 'Source'} Store

                                    {otherStoreSelected && otherStoreSelected?.type &&
                                    <Tag
                                        size="l"
                                        style={{marginLeft: 16, marginTop: 4, fontSize: '16px', padding: '4px'}}
                                        color={otherStoreSelected?.type === 'source' ? "green" : "orange"}>
                                        {otherStoreSelected?.type?.toUpperCase()}
                                    </Tag>
                                    }
                                </h3>
                                <Row className='w-100'>
                                    <div className='w-100'>
                                        <Select
                                            className='custom-select'
                                            value={otherStoreSelected ? {
                                                label: otherStoreSelected?.[0]?.store_url,
                                                value: otherStoreSelected?.[0]?.store_sync_id
                                            } : null}
                                            options={otherDropDownOptions}
                                            isLoading={loadingStores}

                                            isSearchable={true}
                                            onChange={async (data) => {
                                                const item = otherStores.find(a => a.store_sync_id === data.value);
                                                console.log(item,otherStoreSelected);
                                                setOtherStoreSelected([item]);
                                                await handleOtherDropdownChange(item , 'notSource');
                                            }}
                                        />
                                    </div>
                                </Row>
                            </div>
                        </div>
                        <div className="product_search_container">
                            <Row style={{width: '100%'}}>
                                <Col style={{width: '100%'}}>
                                    <div className="product_search_inner">
                                        <span style={{width: '100%'}}>
                                            <input className="custom-input" placeholder={"Search for products"} onChange={handleFilterChange}
                                                   value={filter}/>
                                        </span>
                                        {/* Brand Dropdown */}
                                        <span  className="product_status_drop" style={{marginLeft: '16px'}}>
                                        {/*<Ant_Select*/}
                                        {/*    showSearch*/}
                                        {/*    placeholder="Select Product Status"*/}
                                        {/*    optionFilterProp="children"*/}
                                        {/*    onChange={handleBrandChange}*/}
                                        {/*    style={{width: 200, height:50}}*/}
                                        {/*>*/}
                                        {/*    {items && items.map((item, index) => {*/}
                                        {/*        console.log(items)*/}
                                        {/*        return (*/}
                                        {/*            <Option key={index}*/}
                                        {/*                    value={item.key ? item.key : ""}>{item.label}</Option>*/}
                                        {/*        );*/}
                                        {/*    })}*/}
                                        {/*</Ant_Select>*/}
                                    </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {/*<div style={{paddingLeft:20}} className='button-wrap'>*/}
                        {/*    {currentStoreSelected && otherStoreSelected &&*/}
                        {/*    <>*/}
                        {/*        <Row>*/}
                        {/*            <div>*/}
                        {/*                <div className='ant-btn-default1'*/}
                        {/*                     onClick={() => handleOtherDropdownChange(otherStoreSelected, currentPageUrl, currentPage)}>Refresh*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </Row>*/}
                        {/*        /!*<Button type="primary" onClick={handleSync}>*!/*/}
                        {/*        /!*    Sync Selected Items*!/*/}
                        {/*        /!*</Button>*!/*/}


                        {/*    </>*/}
                        {/*    }*/}
                        {/*</div>*/}


                    </div>

                    <div>
                        <Modal
                            title="Sync Full Catalog"

                            style={{
                                fontSize: '20px'
                            }}
                            width={580}
                            centered
                            okText="Confirm"
                            open={modalOpen}
                            onOk={async () => {
                                await startBulkMaping();
                            }}
                            onCancel={() => setModalOpen(false)}
                            cancelButtonProps={{disabled: loading}}
                            okButtonProps={{disabled: loading, loading: loading}}
                        >
                            <p className='desc'> Once you click on confirm, the source product data will sync
                                to destination store</p>
                        </Modal>
                    </div>
                    <div className="productTable">

                        <Table
                            className='custom-table-wrap product-table'
                            rowSelection={rowSelection}
                            columns={column}
                            dataSource={tabledata}
                            loading={loadingTable}
                            pagination={
                                {
                                    current: currentPage,
                                    pageSize:50,
                                    total:totalRecords,
                                    showSizeChanger:false,
                                    onChange: async (page) => {
                                        console.log(tabledata)
                                        setCurrentPage(page);
                                        handleOtherDropdownChange(otherStoreSelected, currentPageUrl, page);
                                    }
                                }
                            }
                            onRow={(record) => ({
                                onClick: () => {
                                    setOpen(true);
                                    // console.log("HERE")
                                    setProductId(record.product_id);
                                    // console.log(otherStoreSelected.store_sync_id);
                                    // console.log(record);
                                    setStoreId(otherStoreSelected.store_sync_id || otherStoreSelected[0]?.store_sync_id);
                                },
                            })}
                        />

                        {/*{*/}
                        {/*    tabledata.length > 0 &&*/}
                        {/*    (*/}
                        {/*        <div className='pagination_wrap'>*/}
                        {/*            <button onClick={() => {*/}
                        {/*                handleOtherDropdownChange(otherStoreSelected, previousPageLink, currentPage - 1)*/}
                        {/*            }} disabled={currentPage === 1 || loadingTable}>Previous*/}
                        {/*                <svg viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                    <path*/}
                        {/*                        d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/>*/}
                        {/*                </svg>*/}
                        {/*            </button>*/}

                        {/*            {!loadingTable && (*/}
                        {/*                <div className='pagination_text' style={{}}>*/}
                        {/*                    <span>Page {currentPage} of {Math.ceil(totalRecords / 50)}</span>*/}
                        {/*                </div>*/}
                        {/*            )}*/}

                        {/*            <button onClick={() => {*/}
                        {/*                handleOtherDropdownChange(otherStoreSelected, nextPageLink, currentPage + 1)*/}
                        {/*            }} disabled={currentPage === Math.ceil(totalRecords / 50) || loadingTable}>Next*/}
                        {/*                <svg viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                    <path*/}
                        {/*                        d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/>*/}
                        {/*                </svg>*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*}*/}
                    </div>
                </> :
                <>
                    <Drawer
                        title={<h1 className="product-heading" >Product details</h1>}
                        placement={"right"}
                        width={"80%"}
                        onClose={handleClose}
                        open={isOpen}
                    >
                        <ProductModal
                            product_id={productId}
                            store_sync_id={storeId}
                            handleClose={handleClose}
                        />
                    </Drawer>
                </>

            }
        </>
    )
}

export default ProductDetails;
