import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from 'react-router-dom';
import NavigationBar from './components/NavigationBar/NavigationBar';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';

import Home from './containers/Home/Home';
import StoreMapping from './containers/StoreMapping/StoreMapping';
import ProductDetails from './containers/Products/Products';
import Settings from './containers/Settings/Settings';
import Profile from './containers/Profile/Profile';
import OrderDetails from './containers/Orders/Orders';
import StoreDetails from "./containers/StoreDetails";

import OAuthBegin from './components/OAuthBegin/OAuthBegin';
import OAuthEnd from './components/OAuthEnd/OAuthEnd';
import ExpireToken from "./containers/ExpireToken/ExpireToken";

function AppRouter() {
    return (
        <Router>
            <Routes>
                
                <Route path="/" element={<Navigate to="home" />} />
                <Route path="/select_type" element={<StoreDetails />} />
                <Route path="/session_expired" element={<ExpireToken />} />

                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <NavigationBar />
                        </PrivateRoute>
                    }
                >
                    <Route path="home" element={<Home />} />
                    <Route path="storeMapping" element={<StoreMapping />} />
                    <Route path="products" element={<ProductDetails />} />
                    <Route path="orders" element={<OrderDetails />} />
                    <Route path="settings" element={<Settings />} />
                </Route>
                <Route path="profile" element={<Profile />} />

                {/* OAuth Related Routes */}
                <Route
                    path="/oauth"
                    // element={
                    //     <PrivateRoute>
                    //         <OAuth />
                    //     </PrivateRoute>
                    // }
                >
                    <Route path="begin" element={<OAuthBegin />} />
                    <Route path="end" element={<OAuthEnd />} />

                </Route>
            </Routes>
        </Router>
    );
}

export default AppRouter;
