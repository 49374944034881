import {Button, Card, Space} from "antd";
import React, {useEffect, useState} from "react";

const ExpireToken = () => {
    const [num, setNum] = useState(5)
    const counter = () => {
        if(num - 1 >= 0) {
            setTimeout(() => {
                setNum(num - 1);
                counter()
            }, 1000)
        }
        else{
            window.location.href = "https://admin.shopify.com"
        }
    }

    useEffect(() => {
        counter()
    })

    return(
        <div className="content_wrapper">
            <h2 className="productTitle">
                Session Expired
            </h2>
            <div className="shop_page_container" style={{textAlign: 'center'}}>
                <h2 className="subheading">Please login again</h2>
                <h2 className="subheading"> You'll be redirected to Shopify in : {num}</h2>
            </div>
        </div>
    );
}

export default ExpireToken;