import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { JSEncrypt } from "jsencrypt";

export { PrivateRoute };

function decodeBase64(encodedString) {
    // Decode the Base64 string to a standard string

    console.log(" fun encoded", encodedString);

    const privateKey = `
    -----BEGIN RSA PRIVATE KEY-----
    MIICXQIBAAKBgQDlOJu6TyygqxfWT7eLtGDwajtNFOb9I5XRb6khyfD1Yt3YiCgQ
    WMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76xFxdU6jE0NQ+Z+zEdhUTooNR
    aY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4gwQco1KRMDSmXSMkDwIDAQAB
    AoGAfY9LpnuWK5Bs50UVep5c93SJdUi82u7yMx4iHFMc/Z2hfenfYEzu+57fI4fv
    xTQ//5DbzRR/XKb8ulNv6+CHyPF31xk7YOBfkGI8qjLoq06V+FyBfDSwL8KbLyeH
    m7KUZnLNQbk8yGLzB3iYKkRHlmUanQGaNMIJziWOkN+N9dECQQD0ONYRNZeuM8zd
    8XJTSdcIX4a3gy3GGCJxOzv16XHxD03GW6UNLmfPwenKu+cdrQeaqEixrCejXdAF
    z/7+BSMpAkEA8EaSOeP5Xr3ZrbiKzi6TGMwHMvC7HdJxaBJbVRfApFrE0/mPwmP5
    rN7QwjrMY+0+AbXcm8mRQyQ1+IGEembsdwJBAN6az8Rv7QnD/YBvi52POIlRSSIM
    V7SwWvSK4WSMnGb1ZBbhgdg57DXaspcwHsFV7hByQ5BvMtIduHcT14ECfcECQATe
    aTgjFnqE/lQ22Rk0eGaYO80cc643BXVGafNfd9fcvwBMnk0iGX0XRsOozVt5Azil
    psLBYuApa66NcVHJpCECQQDTjI2AQhFc1yRnCU/YgDnSpJVm1nASoRUnU8Jfm3Oz
    uku7JUXcVpt08DFSceCEX9unCuMcT72rAQlLpdZir876
    -----END RSA PRIVATE KEY-----`;

    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(privateKey);
    const decodedString = decrypt.decrypt(encodedString.toString());
    console.log(decodedString, "decodedString")

    return decodedString
}


const checkRequestFromMinOs= ()=>{
    // Get the current URL
    const currentUrl = window.location.href;

    // Create a URL object
    const url = new URL(currentUrl);

    // Get the search parameters from the URL
    const searchParams = new URLSearchParams(url.search);

    // Retrieve specific parameter values
    let param = searchParams.get('minos');

    console.log('param:', param);

    if(!param) return false;

    param = decodeBase64(param);

    // Log the parameter values
    console.log('param:', param);
    const decoded =

    param = JSON.parse(param);

    if(!param?.store_url || !param?.select_store_url) return false;
    return true;
}

function PrivateRoute({ children }) {
    const location = useLocation();
    const { token } = useSelector((state) => state.auth);

    // Fetch user from localStorage
    const storedUser = sessionStorage.getItem('user');

    // Convert string to object, since localStorage can only store strings.
    const user = storedUser ? JSON.parse(storedUser) : null;

    console.log(token)

    // Check if a user token exists in the Redux store or if a user exists in localStorage
    let isAuthenticated = token || user;

    //Setting redirect URL after user logged in (If user not logged in yet)
    // if (!isAuthenticated) {
    //     localStorage.setItem('loginRedirectURL', window.location.href);
    // }

    if (!isAuthenticated) {
        if(checkRequestFromMinOs()) isAuthenticated = true
    }

    console.log(isAuthenticated, "isAuthenticated")

    return isAuthenticated ? (
        children
    ) : (
        <Navigate to="/session_expired" replace state={{ from: location }} />
    );
}