import { useState, useEffect, useMemo, useCallback } from 'react';
import './Orders.css';
import {Table, Modal, Row, Switch, Select, Col, Button} from 'antd';
// const { Search } = Input;
// const { Option } = Select;
import syncErrorIcon from '../../assets/icons/svg/syncErrorIcon.svg';
import { useStoreService, useOrderService } from '../..//services';
import { Notification } from '../../utilities/notification';
// import type { MenuProps } from 'antd';
import Papa from 'papaparse';
import {debounce} from 'lodash';
import {
    CheckCircleTwoTone, CloseCircleTwoTone,
    SyncOutlined
} from '@ant-design/icons';
import {Option} from "antd/es/mentions";
import axios from "axios";
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css"

const SyncErrorIcon = () => <img src={syncErrorIcon} alt="syncError Icon" />;


function OrderDetails() {
    const [tabledata, setTabledata] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [addShippingmodal, setAddShippingModal] = useState(false);
    const [currentStoreSelected, setCurrentStoreSelected] = useState(null);
    const [currentStores, setCurrentStores] = useState([]);
    const [loadingStores, setLoadingStores] = useState(false);
    const[currentCheckoutList, setCurrentCheckoutList] = useState([]);
    const[currentDestination, setCurrentDestinantion] = useState();
    const [distSyncId,setDistSyncId] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [filter, setFilter] = useState(null);
    const [brandFilter, setBrandFilter] = useState(0);
    const [payFilter, setPayFilter] = useState(0);
    const [csvOutput,setCsvOutput]=useState(null);
    const [startDate,setStartDate]=useState(null);
    const [endDate,setEndDate]=useState(null);
    const [brandItems, setBrandItems]=useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltip2, setShowTooltip2] = useState(false);
    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }

    const storeService = useStoreService();
    const orderService = useOrderService();


    useEffect(() => {
        (async () => {
            setLoadingStores(true);
            const current_store_details = storeService.getCurrentStoreDetails();
            setCurrentStoreSelected(current_store_details);

            const destination_sync_id = current_store_details?.store_sync_id;
            console.log("In",destination_sync_id)
            setCurrentStoreSelected({ store_sync_id: destination_sync_id, type: 'destination'});

            await handleCurrentDropdownChange();
            setLoadingStores(false);
        })();
    }, [currentPage, brandFilter, payFilter, startDate, endDate, setCurrentStoreSelected]);
    useEffect(() => {
        // Skip the initial call (when the component mounts) if searchTerm is empty

        let timerId;
        console.log(filter)
        // Call the debounced function after a delay
       if(filter!==null){
           timerId = setTimeout(() => {
               debouncedFetch();
               setCurrentPage(1)
           }, 300);
       } else if(filter===''){

           clearTimeout(timerId);
           timerId = setTimeout(() => {
               debouncedFetch();
               setCurrentPage(1)
           }, 300);
       }

        // Clean-up function to cancel any pending debounced calls
        return () => {
            clearTimeout(timerId);
        };
    }, [filter]);

    async function handleSubmitChange(mapped_source_checkouts, destination_order_details){
        try {
            const checkout_line_items = await orderService.getCheckoutLineItems({"destination_order_id": destination_order_details?.destination_order_id})
            console.log(checkout_line_items);

            setCurrentCheckoutList(checkout_line_items);

            setAddShippingModal(true)
        }
        catch (e){
            console.log(e)
        }
    }

    const onSwitchChange = async (checked, source_order_id) => {
        // console.log(`switch to ${checked}`, source_order_id);
        const res = await orderService.togglePaid({
            'paid': !checked,
            'source_order_id': source_order_id
        })
        // console.log(res);
        if(!res){
            Notification('error', 'Something went wrong please refresh and try again')
        }
        else{
            Notification('success', 'Payment information updated')
        }
        return !checked
    }

    const column = [
        {
            title: 'Mapped Source Orders',
            dataIndex: 'mapped_source_orders',
            render: (_, { mapped_source_orders }) => (
                <>
                    <p><a href={mapped_source_orders.order_status_url} target="_blank">#{mapped_source_orders.source_order_number}</a></p>
                </>
            )
        },
        {
            title: 'Brand Name',
            dataIndex: 'brand_name',
            render: (_, { brand_name }) => (
                <>
                        <p className="tooltiptext">{brand_name}</p>
                </>
            )
        },
        {
            title: 'CMS Internal ID',
            dataIndex: 'destination_order_id',
            render: (_, { destination_order_id }) => (
                <>
                    <div
                        className="tooltip"
                        onMouseEnter={() => setShowTooltip2(true)}
                        onMouseLeave={() => setShowTooltip2(false)}
                    >
                        {showTooltip2 && (
                            <pre className="tooltiptext">
                       cms.minoanexperience.com/b2b-store/order-details/{destination_order_id}
                    </pre>
                        )}
                        <p>
                            <a
                                href={`https://cms.minoanexperience.com/b2b-store/order-details/${destination_order_id}`}
                                target="_blank"
                            >
                                #{destination_order_id}
                            </a>
                        </p>
                    </div>
                </>
            )
        },
        {
            title: 'CMS External ID',
            dataIndex: 'cms_external_id',
            render: (_, { cms_external_id }) => (
                <>
                    <div
                        className="tooltip"
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        {showTooltip && (
                            <pre className="tooltiptext">
                        cms.minoanexperience.com/b2b-store/order-details/{cms_external_id}
                    </pre>
                        )}
                        <p>
                            <a
                                href={`https://cms.minoanexperience.com/b2b-store/order-details/${cms_external_id}`}
                                target="_blank"
                            >
                                #{cms_external_id}
                            </a>
                        </p>
                    </div>
                </>
            )
        },
        {
            title: 'Total Merchandized Cost',
            dataIndex: 'merchant_cost',
            render: (_, { merchant_cost }) => (
                <>
                    <p className="tooltiptext">{merchant_cost.toFixed(2)}</p>
                </>
            )
        },
        {
            title: 'Shipping Cost',
            dataIndex: 'shipping_cost',
            render: (_, { shipping_cost }) => (
                <>
                    <p className="tooltiptext">{shipping_cost.toFixed(2)}</p>
                </>
            )
        },
        {
            title: "Additional Discount",
            dataIndex: "additional_discount_percentage",
            render: (_, { additional_discount_percentage }) => (
                <>
                    { additional_discount_percentage > 0 ?
                        <p className="tooltiptext">{additional_discount_percentage.toFixed(2)}%</p>
                        :
                        <p className="tooltiptext">-</p>
                    }
                </>
            )
        },
        {
            title: "Discounted Merchant Cost",
            dataIndex: "discounted_merchant_cost",
            render: (_, { discounted_merchant_cost }) => (
                <>
                    <p className="tooltiptext">{discounted_merchant_cost.toFixed(2)}</p>
                </>
            )
        },
        {
            title: "Discounted Shipping Cost",
            dataIndex: "discounted_shipping_cost",
            render: (_, { discounted_shipping_cost }) => (
                <>
                    <p className="tooltiptext">{discounted_shipping_cost.toFixed(2)}</p>
                </>
            )
        },
        {
            title: 'Total Cost',
            dataIndex: 'merchant_cost',
            render: (_, { discounted_merchant_cost, discounted_shipping_cost }) => (
                <>
                    <p className="tooltiptext">{(discounted_merchant_cost + discounted_shipping_cost).toFixed(2)}</p>
                </>
            )
        },
        {
            title: 'Savings on Net Terms',
            dataIndex: 'merchant_cost',
            render: (_, { discounted_merchant_cost, discounted_shipping_cost, merchant_cost, shipping_cost}) => (
                <>
                    <p className="tooltiptext">{((merchant_cost + shipping_cost) - (discounted_merchant_cost + discounted_shipping_cost)).toFixed(2)}</p>
                </>
            )
        },
        {
            title: 'Order date',
            dataIndex: 'source_order_created_at',
            render: (_, { source_order_created_at }) => (
                <>
                        <span className="tooltiptext">{(new Date(source_order_created_at).toLocaleDateString('en-US',{
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric'
                        }))}</span>
                </>
            )
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            render: (_, {paid, source_order_id}) => {
                let toggle_paid = paid;
                return (
                <>
                    <Switch defaultChecked={toggle_paid} onChange={async () => {
                        toggle_paid = await onSwitchChange(toggle_paid, source_order_id)
                    }}/>
                </>
            )
            }
        }
        // {
        //     title: 'Status',
        //     dataIndex: 'mapped_source_orders',
        //     render: (_, { mapped_source_orders }) => (
        //         <>
        //             {mapped_source_orders && mapped_source_orders.length ? (
        //                 <CheckCircleTwoTone
        //                     twoToneColor="#1DD75BFF"
        //                     style={{
        //                         fontSize: 20
        //                     }} />
        //             ) : (
        //                 <CloseCircleTwoTone
        //                     twoToneColor="#F22128FF"
        //                     style={{
        //                         fontSize: 20
        //                     }} />
        //             )}
        //         </>
        //     )
        // },
        // {
        //     title: 'Action',
        //     dataIndex: 'mapped_source_checkouts',
        //     render: (_, {mapped_source_checkouts, destination_order_details}) => (
        //         <>
        //             <Button size="middle" onClick={() => {handleSubmitChange(mapped_source_checkouts, destination_order_details)}}>
        //                 <a>View Order Details</a>
        //             </Button>
        //         </>
        //     )
        // },

    ];


    const handleCurrentDropdownChange = async () => {
        console.log("RUNING")

        setLoadingTable(true);
        setTabledata([]);
        // console.log(currentPage, "page", Math.max(currentPage - 1, 0))
        const requestbody = {
            "page": Math.max(currentPage - 1, 0),
            "destination_sync_id" : currentStoreSelected?.store_sync_id,
            'filter': filter,
            'brandFilter': brandFilter,
            'payFilter': payFilter,
            'startDate': startDate,
            'endDate': endDate
        }
        console.log(requestbody, "requestBody")
        // console.log(currentStoreSelected?.store_sync_id);
        setDistSyncId(currentStoreSelected?.store_sync_id)
        try {
            console.log(requestbody, "requestbody")
            const response = await orderService.getOrderList(requestbody);
            if(response) {
                setTotalPages(response['page_count'])
                console.log(response, "orders list");
                // setTotalRecords(response?.length);
                let processedData = response.list.map((item, index) => {
                    return {
                        key: index,
                        mapped_source_orders: {
                            order_status_url: item.order_status_url,
                            source_order_number: item.source_order_number
                        },
                        destination_order_id: item.destination_order_id,
                        destination: item.destination,
                        source_order_id: item.source_order_id,
                        source_sync_id: item.source_sync_id,
                        source_store_url: item.source_store_url,
                        source_order_created_at: item.source_order_created_at,
                        cms_external_id: item.cms_external_id,
                        brand_id: item.brand_id,
                        brand_name: item.brand_name,
                        paid: item.paid || false,
                        merchant_cost: item.merchant_cost || 0,
                        shipping_cost: item.shipping_cost || 0,
                        additional_discount_percentage: item.additional_discount_percentage || 0,
                        discounted_merchant_cost: item.discounted_merchant_cost || (item.merchant_cost || 0),
                        discounted_shipping_cost: item.discounted_shipping_cost || (item.shipping_cost || 0)
                    }
                });
                setTabledata(processedData);
            }

            // console.log(processedData, "processed data");

            let brand_list_res = await orderService.getBrandsList();
            if(brand_list_res) {
                console.log(brand_list_res, "brand_list_res")
                brand_list_res = [{brand_id: 0, brand_name: "Select Brand"}].concat(brand_list_res)

                console.log(JSON.stringify(brand_list_res), "brand_list_res")
                let brand_items = brand_list_res.map((brand_obj) => {
                    return {
                        key: brand_obj.brand_id,
                        label: brand_obj.brand_name
                    }
                })
                console.log(brand_items, "brand_items")
                setBrandItems(brand_items)
            }


            setLoadingTable(false);

            // console.log(response['page_count'])
            // setCurrentPage(currentPage);
        } catch (error) {
            console.log(error);
            setLoadingTable(false);
            setTabledata([]);
        }
    }


    const debouncedFetch = debounce(handleCurrentDropdownChange, 300);

    const handleFilterChange = async event => {
        setFilter(event.target.value?event.target.value:"");
        console.log('value is:', event.target.value);
    };



    // let brand_items = [
    //     {
    //         key: '',
    //         label: "Select Brand"
    //     }
    // ];

    let items = [
        {
            key: '',
            label: "Select Payment"
        },
        {
            key: 'true',
            label: "Paid"
        },
        {
            key: 'false',
            label: "Unpaid"
        }
    ];

    const handleBrandChange = (value) => {
        console.log(value)
        setBrandFilter(value);
        setCurrentPage(1);
    };

    const handlePaymentChange = (value) => {
        console.log(value)
        setPayFilter(value);
        setCurrentPage(1);
    };

    const convertToCsv = (jsonData) => {
        const csv = Papa.unparse(jsonData);
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const downloadLink = document.createElement('a');
        downloadLink.href = csvUrl;
        downloadLink.download = 'output.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    const getAllData=async()=>{
        let data=[];
        let requestbody = {
            "page": Math.max(currentPage - 1, 0),
            "destination_sync_id" : currentStoreSelected?.store_sync_id,
            'filter': filter,
            'brandFilter': brandFilter,
            'payFilter': payFilter,
            'startDate': startDate,
            'endDate': endDate
        }
        let jsonInput=[];
        let page=Math.ceil(totalPages/10);
        for(let i=0;i<page;i++){
            requestbody={...requestbody,page:i};
            let res= await orderService.getOrderList(requestbody);
            res.list.map(val=>{
               let temp={
                   "Mapped Source Orders":val.source_order_number,
                   "Brand Name":val.brand_name,
                   "CMS Internal ID":val.destination_order_id,
                   "CMS External ID":val.cms_external_id,
                   "Total Merchandized Cost":val.merchant_cost || 0,
                   "shipping cost": val.shipping_cost || 0,
                   "Additional Discount": (val.additional_discount_percentage || 0) + "%",
                   "Discounted Merchant Cost":  val.discounted_merchant_cost || (val.merchant_cost || 0),
                   "Discounted Shipping Cost": val.discounted_shipping_cost || (val.shipping_cost || 0),
                   "Total Cost": 0,
                   "Savings on Net Terms": 0,
                   "Order date":val.source_order_created_at,
                   "Paid":val.paid?val.paid:false
               };
                temp["Total Cost"] = temp["Discounted Merchant Cost"] + temp["Discounted Shipping Cost"]
                temp["Savings on Net Terms"] =
                    (temp["Total Merchandized Cost"] + temp["shipping cost"]) - temp["Total Cost"]
                jsonInput.push(temp);
            });
        }
        convertToCsv(jsonInput)

    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
    const handleRangeChage=(ranges)=>{
      if(ranges && ranges.length){
          let startDate=formatDate(ranges[0]);
          let endDate=formatDate(ranges[1]);
          startDate+='T00:00:00';
          endDate+='T23:59:59';
          setStartDate(startDate);
          setEndDate(endDate);
          setCurrentPage(1);
          console.log(startDate,endDate)
      } else {
          setStartDate(null);
          setEndDate(null);

      }
    }


    return (
        <>
            <div class="content_head_ui">
                <h2 className="heading">Orders </h2>
            </div>

            <Row className="order_inner-container" align={'middle'} justify={'space-between'} style={{width:'100w'}}>
                <Col>
                    <div className={"order-dropdown-outer"}>
                        <span>
                            <input className="custom-input1" placeholder={"Search for orders"} onChange={handleFilterChange} value={filter} />
                        </span>
                        {/* Brand Dropdown */}
                        <span style={{ marginLeft: '16px' }}>
                            <Select
                              showSearch
                              placeholder="Select Brand"
                              optionFilterProp="children"
                              className="custom-input2"
                              onChange={handleBrandChange}
                              style={{ width: 200 }}
                            >
                                {brandItems.map(item => (
                                    <Option  key={item.key} value={item.key}>{item.label}</Option>
                                ))}
                            </Select>
                        </span>

                        {/* Payment Dropdown */}
                        <span style={{ marginLeft: '16px' }}>
                            <Select
                                showSearch
                                placeholder="Select Payment"
                                optionFilterProp="children"
                                className="custom-input2"
                                onChange={handlePaymentChange}
                                style={{ width: 200 }}
                            >
                                {items.map(item => (
                                    <Option key={item.key} value={item.key}>{item.label}</Option>
                                ))}
                            </Select>
                        </span>
                        <span style={{ marginLeft: '16px' }}>
                            <DateRangePicker  className="custom-datepicker-order" format="MM/dd/yyyy" character=" – " onChange={handleRangeChage}/>
                        </span>
                  </div>
              </Col>
              <Col>
                  <Button className="custom-input1" onClick={getAllData}>Export to CSV</Button>
              </Col>
          </Row>

            <div className="orderTable">
                <Table
                    className='custom-table-wrap'
                    columns={column}
                    dataSource={tabledata}
                    loading={loadingTable}
                    page={currentPage}

                    pagination={
                        {
                            current: currentPage,
                            pageSize: 10,
                            showSizeChanger:false,
                            total: totalPages,
                            onChange: async (page) => {
                                console.log(page)
                                setCurrentPage(page);
                            }
                        }
                    }
                />
            </div>


            {/*{addShippingmodal &&*/}

            {/*<Modal*/}
            {/*    width={700}*/}
            {/*    title="Place Order"*/}
            {/*    style={{*/}
            {/*        textAlign: 'left'*/}
            {/*    }}*/}
            {/*    centered*/}
            {/*    open={addShippingmodal}*/}
            {/*    onCancel={() => setAddShippingModal(false)}*/}
            {/*    footer={null}*/}
            {/*>*/}
            {/*    {currentCheckoutList && currentCheckoutList.map((group) => {*/}
            {/*        return (*/}

            {/*            <>*/}
            {/*                {group.line_items.map((line_item) => {*/}
            {/*                    return (*/}
            {/*                        <div key={line_item.id}>*/}
            {/*                            <Row>Vendor: {line_item.vendor}</Row>*/}
            {/*                            <Row>Product Title: {line_item.title}</Row>*/}
            {/*                            <Row>product Prize: {line_item.price}</Row>*/}
            {/*                            <Row>Quantity: {line_item.quantity}</Row>*/}
            {/*                            <Row>*******************************************************************</Row>*/}
            {/*                        </div>*/}
            {/*                    );*/}
            {/*                })}*/}
            {/*            </>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</Modal>*/}
            {/*}*/}
        </>
    );
}

export default OrderDetails;
