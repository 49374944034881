import axios from 'axios';
import { BASE_API_URL } from '../../constants/routes';
import { AuthActions } from '../slices/auth.slice';
import { UserActions } from '../slices/user.slice';

// Action creator for checking user's authorization from localStorage
export const checkAuthorization = () => {
    return async (dispatch) => {
        try {
            // Fetch the token and refreshToken from localStorage
            const storedUserData = localStorage.getItem('user');

            const storedToken = storedUserData?.data?.access_token;
            const storedRefreshToken = storedUserData?.data?.access_token;

            // Fetch the user data from localStorage

            // Parse the user data into a JavaScript object since localStorage can only store strings
            const userData = storedUserData ? JSON.parse(storedUserData) : null;

            if (storedToken && storedRefreshToken) {
                // Update Redux store with token and refreshToken
                dispatch(AuthActions.setAuth({ token: storedToken, refreshToken: storedRefreshToken }));
            }

            if (userData) {
                // Update Redux store with user data
                dispatch(UserActions.setUser(userData));
            }
        } catch (err) {
            console.log('Error - Check Authorization Action Error - ', err);
            throw err;
        }
    };
};